<template>
  <div class="service">
    <img-div
      height="400"
      class-name=""
      :img-url="require('@/assets/service/mask.png')"
    />
    <div class="tabnav container flex flex-jsb">
      <div
        class="navItem"
        v-for="(item, index) in navArr"
        :key="index"
        @click="$router.push(item.path)"
      >
        <el-image :src="item.imgUrl" fit="scale-down" class="navImg"></el-image>
        <div class="listItemTitle">{{ item.name }}</div>
      </div>
    </div>
    <div class="servicePage">
      <transition name="page" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
export default {
  components: {
    ImgDiv,
  },
  computed: {
    navArr() {
      return this.$t("service.navArr");
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.service {
  position: relative;
  .tabnav {
    padding: 37px 153px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.13);
    position: absolute;
    left: 50%;
    top: 300px;
    transform: translateX(-50%);
    .navItem {
      width: 120px;
      cursor: pointer;
    }
  }
  .servicePage {
    margin-top: 200px;
    // min-height: 100px;
  }
  .page {
    &-enter {
      opacity: 0;
      transform: translateX(-30px);
    }

    &-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    &-enter-active,
    &-leave-active {
      transition: all 0.3s ease;
    }
  }
}
</style>
